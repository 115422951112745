import { PriceCategory } from "@/types/index";
import { ActionContext } from "vuex";
import { supabase } from "@/composables/supabaseClient";

interface ArticleState {
  priceList: PriceCategory[];
}

export default {
  state: {
    priceList: [] as PriceCategory[],
  },
  getters: {
    priceList(state: ArticleState): PriceCategory[] {
      return state.priceList;
    },
  },
  mutations: {
    setPriceList(state: ArticleState, priceList: PriceCategory[]) {
      state.priceList = priceList;
    },
  },
  actions: {
    async fetchPriceCategoriesAndPrice(
      context: ActionContext<ArticleState, any>
    ) {
      const { data: categories } = await supabase
        .from("price-categories")
        .select("*");

      const { data: prices } = await supabase.from("price").select("*");

      if (categories && prices) {
        const priceList = categories.map((category) => ({
          ...category,
          prices: prices.filter(
            (price) => price.priceCategoryId === category.id
          ),
        }));

        context.commit("setPriceList", priceList);
      }
    },
  },
};
