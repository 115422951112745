import { ActionContext } from "vuex";
import { supabase } from "@/composables/supabaseClient";

type Config = Record<string, boolean>;
interface configState {
  config: Config;
}

export default {
  state: {
    config: null,
  },
  getters: {
    config(state: configState): Config {
      return state.config;
    },
  },
  mutations: {
    setConfig(state: configState, config: Config) {
      state.config = config;
    },
  },
  actions: {
    async fetchConfig(context: ActionContext<configState, any>) {
      const { data } = await supabase.from("config").select("*");
      if (data) {
        context.commit("setConfig", data[0]);
      }
    },
  },
};
