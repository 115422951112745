import { Doctor } from "@/types/index";
import { ActionContext } from "vuex";
import { supabase } from "@/composables/supabaseClient";

interface ArticleState {
  doctors: Doctor[];
  loadedDoctors: boolean;
  loadedDoctorsDocs: boolean;
}

export default {
  state: {
    doctors: [] as Doctor[],
    loadedDoctors: false,
    loadedDoctorsDocs: false,
  },
  getters: {
    doctors(state: ArticleState): Doctor[] {
      return state.doctors;
    },
    loadedDoctors(state: ArticleState): boolean {
      return state.loadedDoctors;
    },
    loadedDoctorsDocs(state: ArticleState): boolean {
      return state.loadedDoctorsDocs;
    },
  },
  mutations: {
    setDoctors(state: ArticleState, doctors: Doctor[]) {
      state.doctors = doctors;
    },
    setLoadedDoctors(state: ArticleState, value: boolean) {
      state.loadedDoctors = value;
    },
    setLoadedDoctorsDocs(state: ArticleState, value: boolean) {
      state.loadedDoctorsDocs = value;
    },
  },
  actions: {
    async fetchDoctors(context: ActionContext<ArticleState, any>) {
      const { data: doctors } = await supabase
        .from("doctors")
        .select("*")
        .order("index", { ascending: true });

      context.commit("setDoctors", doctors);
      context.commit("setLoadedDoctors", true);

      if (doctors && doctors.length > 0) {
        const promises = doctors.map((doctor) => {
          return supabase.storage
            .from(`docs`)
            .list(`${doctor.key}/`)
            .then(({ data: docs }) => {
              if (docs) {
                doctor.docs = docs;
              }
              return doctor; // Если вам нужно вернуть docs
            });
        });

        Promise.all(promises)
          .then((results) => {
            context.commit("setDoctors", results);
            context.commit("setLoadedDoctorsDocs", true);
          })
          .catch((error) => {
            console.error("Error fetching files:", error);
          });
      }
    },
  },
};
