import { Doctor } from "@/types/index";
import { ActionContext } from "vuex";
import { supabase } from "@/composables/supabaseClient";

interface ArticleState {
  clinicPhotos: Doctor[];
  loadedClinicPhotos: boolean;
}

export default {
  state: {
    clinicPhotos: [] as Doctor[],
    loadedClinicPhotos: false,
  },
  getters: {
    clinicPhotos(state: ArticleState): Doctor[] {
      return state.clinicPhotos;
    },
    loadedClinicPhotos(state: ArticleState): boolean {
      return state.loadedClinicPhotos;
    },
  },
  mutations: {
    setClinicPhotos(state: ArticleState, clinicPhotos: Doctor[]) {
      state.clinicPhotos = clinicPhotos;
    },
    setLoadedClinicPhotos(state: ArticleState, value: boolean) {
      state.loadedClinicPhotos = value;
    },
  },
  actions: {
    async fetchClinicPhotos(context: ActionContext<ArticleState, any>) {
      const { data: photos } = await supabase.storage
        .from(`clinic-photo`)
        .list(``);
      context.commit("setClinicPhotos", photos);
      context.commit("setLoadedClinicPhotos", true);
    },
  },
};
