import { Sale } from "@/types/index";
import { ActionContext } from "vuex";
import { supabase } from "@/composables/supabaseClient";

interface SalesState {
  sales: Sale[];
  loadedSales: boolean;
}

export default {
  state: {
    sales: [],
    loadedSales: false,
    loadedSalesImages: false,
  },
  getters: {
    sales(state: SalesState): Sale[] {
      return state.sales;
    },
    loadedSales(state: SalesState): boolean {
      return state.loadedSales;
    },
  },
  mutations: {
    setSales(state: SalesState, doctors: Sale[]) {
      state.sales = doctors;
    },
    setLoadedSales(state: SalesState, value: boolean) {
      state.loadedSales = value;
    },
  },
  actions: {
    async fetchSales(context: ActionContext<SalesState, any>) {
      const { data: sales } = await supabase
        .from("sales")
        .select("*")
        .order("createdAt");

      if (Array.isArray(sales) && sales.length > 0) {
        context.commit("setSales", sales);
        context.commit("setLoadedSales", true);
      }
    },
  },
};
