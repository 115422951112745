import { ArticleCategory, Article } from "@/types/index";
import { ActionContext } from "vuex";
import { supabase } from "@/composables/supabaseClient";

interface ArticleState {
  articlesCategories: ArticleCategory[];
  articles: Article[];
  selectedCategoryId: string;
  selectedArticleId: string | null;
}

export default {
  state: {
    articlesCategories: [] as ArticleCategory[],
    articles: [] as Article[],
    selectedCategoryId: "all",
    selectedArticleId: null,
  },
  getters: {
    selectedCategory(state: ArticleState): ArticleCategory | undefined {
      const selectedCategory = state.articlesCategories.find(
        (category) => category.id === state.selectedCategoryId
      );
      return selectedCategory;
    },
    articlesCategories(state: ArticleState): ArticleCategory[] {
      return state.articlesCategories;
    },
    articles(state: ArticleState): Article[] {
      if (state.selectedCategoryId === "all") {
        return state.articles;
      } else {
        return state.articles.filter(
          (article) => article.categoryId === state.selectedCategoryId
        );
      }
    },
    selectedArticle(state: ArticleState): Article | null {
      if (state.selectedArticleId) {
        const article = state.articles.find(
          (article) => article.id === state.selectedArticleId
        );
        return article ? article : null;
      } else {
        return null;
      }
    },
  },
  mutations: {
    setSelectedArticleId(state: ArticleState, articleId: string) {
      state.selectedArticleId = articleId;
    },
    setSelectedCategoryId(state: ArticleState, categoryId: string) {
      state.selectedCategoryId = categoryId;
    },
    setArticlesCategories(state: ArticleState, articles: ArticleCategory[]) {
      state.articlesCategories = articles;
    },
    setArticles(state: ArticleState, articles: Article[]) {
      state.articles = articles;
    },
  },
  actions: {
    async fetchArticleCategories(context: ActionContext<ArticleState, any>) {
      const { data } = await supabase.from("article-categories").select();
      data?.unshift({
        id: "all",
        name: "Все статьи",
      });
      context.commit("setArticlesCategories", data);
    },
    async fetchArticles(context: ActionContext<ArticleState, any>) {
      const { data } = await supabase
        .from("articles")
        .select()
        .order("createdAt", { ascending: false });
      context.commit("setArticles", data);
    },
  },
};
