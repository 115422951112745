import { createStore } from "vuex";
import articles from "@/store/articles";
import price from "@/store/price";
import doctors from "@/store/doctors";
import config from "@/store/config";
import clinic from "@/store/clinic";
import sales from "@/store/sales";
export default createStore({
  modules: {
    articles,
    price,
    doctors,
    sales,
    clinic,
    config,
  },
  state: {
    fileStorageUrl:
      "https://wyczpiscwpdeuyjctoxl.supabase.co/storage/v1/object/public",
    reviewsList: [],
    beforeAfterPhotoList: [],
    activeNavIndex: "1",
  },
  getters: {
    getReviewsList(state) {
      return state.reviewsList;
    },
    getBeforeAfterPhotoList(state) {
      return state.beforeAfterPhotoList;
    },
    getActiveNavIndex(state) {
      return state.activeNavIndex;
    },
  },
  mutations: {
    setReviewsList(state, array) {
      state.reviewsList = array;
    },
    setBeforeAfterPhotoList(state, array) {
      state.beforeAfterPhotoList = array;
    },
    setActiveNavIndex(state, value) {
      state.activeNavIndex = value;
    },
  },
});
