import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/legal-information",
    name: "LegalInformationView",
    component: () => import("@/views/LegalInformationView.vue"),
  },
  {
    path: "/reviews-list",
    name: "ReviewsList",
    component: () => import("@/components/ReviewsList.vue"),
  },
  {
    path: "/before-after-photo",
    name: "BeforeAfterPhoto",
    component: () => import("@/components/BeforeAfterPhoto.vue"),
  },
  {
    path: "/price",
    name: "PriceView",
    component: () => import("@/views/PriceView.vue"),
  },
  {
    path: "/articles/:id?",
    name: "ArticlesView",
    component: () => import("@/views/ArticlesView.vue"),
  },
  {
    path: "/article/:id",
    name: "ArticleView",
    component: () => import("@/views/ArticleView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
